<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-card-text class="d-flex">
        <v-avatar rounded size="120" class="me-6">
          <v-img :src="cropped"></v-img>
        </v-avatar>
      </v-card-text>

      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col md="4" cols="12">
              <v-text-field readonlys v-model="branch" label="Branch" dense outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-card flat>
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col md="4" cols="12"></v-col>
                <v-row>
                  <v-col
                    md="12"
                    cols="12"
                  >
                    <v-combobox
                      class="mx-2"
                      :append-icon="icons.mdiAccountSearchOutline"
                      v-model="search_mem"
                      @keyup="searching($event.target.value)"
                      @change="get_search_items_info"
                      :items="search_items"
                      item-value="id"
                      item-text="name"
                      placeholder="Search by (Lastname/Firstname/FSC #)"
                      ref="memberCombobox"
                      dense
                    ></v-combobox>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="last_name"
                      label="Last Name"
                      dense
                      outlined
                      readonly
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="first_name"
                      label="First Name"
                      dense
                      outlined
                      readonly
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="middle_name"
                      label="Middle Name"
                      dense
                      outlined
                      readonly
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-toolbar dense dark color="primary">
                <v-toolbar-title
                ><h4 class="font-weight-light">FAMILY REPRESENTATIVES INFORMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col md="4" cols="12"></v-col>
                <v-row>
                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="representatives_name"
                      label="Name"
                      dense
                      outlined
                      readonly
                      :rules="rules.default_max_100_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="representatives_relationship"
                      label="Relationship"
                      dense
                      outlined
                      readonly
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="representatives_contact_no"
                      label="Contact #"
                      dense
                      outlined
                      readonly
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="12" cols="12">
                    <v-text-field
                      v-model="representatives_address"
                      label="Address"
                      dense
                      outlined
                      readonly
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-toolbar dense dark color="primary">
                <v-toolbar-title
                ><h4 class="font-weight-light">ROOM DETAILS INFORMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col md="4" cols="12"></v-col>
                <v-row>
                  <v-col md="3" cols="12">
                    <v-text-field
                      type="date"
                      v-model="date_started"
                      label="Date of Started"
                      dense
                      outlined
                      :rules="rules.default_max_100_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col md="3" cols="12">
                    <v-select
                      v-model="room_accomodation_id"
                      dense
                      outlined
                      label="Room Type"
                      :items="room_accomodations_items"
                      item-value="id"
                      item-text="room_type"
                      :rules="rules.combobox_rule"
                      @change="selected_room(room_accomodations_items.map(function (x) {
            return x.id;
          }).indexOf(room_accomodation_id))"
                    ></v-select>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      type="number"
                      min="0"
                      v-model="no_of_nights"
                      label="No. of Nights"
                      dense
                      outlined
                      @change="calculate_charge"
                      @keyup="calculate_charge"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      v-model="room_charge_format"
                      label="Room Charge"
                      dense
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-4" @click="assign_rooms" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiAccountSearchOutline} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      cropped: require('@/assets/images/avatars/2.png'),

      saving: false,
      alert: false,
      alert_message: '',

      last_name: '',
      first_name: '',
      middle_name: '',

      representatives_name: '',
      representatives_address: '',
      representatives_contact_no: '',
      representatives_relationship: '',

      search_mem: '',
      search_member: '',
      search_items: [],

      date_started: '',
      room_accomodation_id: '',
      room_accomodations_items: [],
      no_of_nights: 0,
      room_charge_per_night: 0,
      room_charge: 0,
      room_charge_format: 0,
    }
  }

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        show: false,
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    created() {
      this.get_available_rooms()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch', 'branch_id', 'year', 'month_start', 'month_end', 'month_of', 'user_id']),
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member === '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      }
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('deceased_client', ['search_deceased_client']),
      ...mapActions('room_accomodation', ['get_room_accomodation']),
      ...mapActions('room_accomodation_history', ['save_room_accomodation_rented']),
      reset() {
        Object.assign(this.$data, initialState())
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      get_available_rooms() {
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        this.get_room_accomodation(data)
          .then(response => {
            this.room_accomodations_items = response.data
          })
      },
      searching(value) {
        this.search_member = value
      },
      searching_search_items(value) {
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        data.append('search_word', value)
        this.search_deceased_client(data)
          .then(response => {
            this.search_items = response.data
          })
      },
      get_search_items_info() {
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(this.search_mem.id)
          if (index != -1) {
            this.last_name = this.search_mem.last_name
            this.first_name = this.search_mem.name
            this.middle_name = this.search_mem.middle_name
            this.representatives_name = this.search_mem.representatives_name
            this.representatives_address = this.search_mem.representatives_address
            this.representatives_contact_no = this.search_mem.representatives_contact_no
            this.representatives_relationship = this.search_mem.representatives_relationship
          } else {
            this.last_name = ''
            this.first_name = ''
            this.middle_name = ''
            this.representatives_name = ''
            this.representatives_address = ''
            this.representatives_contact_no = ''
            this.representatives_relationship = ''
          }
        } else {
          this.last_name = ''
          this.first_name = ''
          this.middle_name = ''
          this.representatives_name = ''
          this.representatives_address = ''
          this.representatives_contact_no = ''
          this.representatives_relationship = ''
        }
      },
      selected_room(index) {
        this.room_charge_per_night = this.room_accomodations_items[index].amount
        this.no_of_nights = 0
        this.room_charge = 0
      },
      calculate_charge() {
        this.room_charge = parseInt(this.no_of_nights) * parseFloat(this.room_charge_per_night)
        this.room_charge_format = this.formatPrice(this.room_charge)
      },
      assign_rooms() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('room_accomodation_id', this.room_accomodation_id)
          data.append('deceased_client_id', this.search_mem.id)
          data.append('date_started', moment(this.date_started,'YYYY-MM-DD').format('MMMM D, YYYY'))
          data.append('no_of_nights', this.no_of_nights)
          data.append('amount', this.room_charge)
          this.save_room_accomodation_rented(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
    },
  }
</script>
